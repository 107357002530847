import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
// import { firebaseApp as Firebase } from "./firebase.js";
import App from "./App.jsx";

// import 'bootstrap/dist/css/bootstrap.css';
import "./custom.scss";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<StrictMode>
		<div className="App bg-dark">
			<App>'hi'</App>
		</div>
	</StrictMode>
);
