import "./grid.css";
import React from "react";
import { Stack, Container } from "react-bootstrap";
// import { motion } from "framer-motion";
import Tile from "../Tile/Tile";

export default function Grid({
	user,
	rawTiles,
	// Set default tile values
	grid = {
		size: {
			width: 4,
			height: 4,
		},
	},
}) {
	// Map loops to Tile components
	const tileLoops = rawTiles.loops.map((loop, index) => (
		<Tile
			key={`${index}-${loop.id}`}
			className="m-3 w-25"
			tile={loop} 
		/>
	));

	// const mappedGrid = tileLoops.forEach((loop, index) => (

	// ))

	return (
		<Container fluid>
			<Stack direction="vertical" gap={3}>
				{tileLoops}
			</Stack>
		</Container>
	);
}
