import React from "react";
import { useState } from "react";

import { motion } from "framer-motion";
import "./tile.css";

import { ReactComponent as Waveform } from "../../assets/waveform.svg";

export default function Tile({
	// Set default tile values
	tile = {
		// ...tile,
		name: "Crush Loop",
		wave: Waveform,
		color: "#ff55ff",
	},
	index = 1,
	enabled = true,
}) {
	// React state
	const [isHovered, setIsHovered] = useState(false);
	const [isEnabled, setIsEnabled] = useState(enabled);

	// Handlers
	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);
	const handleDisable = () => setIsEnabled(!isEnabled);

	// Styling
	const alpha = isHovered === true ? 60 : 50;
	const bootstrapStyle = `card p-3 radius`;
	const customStyle = {
		color: `${tile.color}`,
		background: `${tile.color}${isEnabled ? alpha - 10 : alpha - 50}`,
		border: `5px solid ${tile.color}${isEnabled ? alpha : alpha - 30}`,
		transition: "0.3s",
	};



	return (
		<motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 1.01 }}>
			<div
				id={`tile-${index}`}
				className={bootstrapStyle}
				style={customStyle}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<div className="card-title">{tile.name}</div>
				<div className="card-body p-0">
					<Waveform
						className="card-img p-0"
						src={tile.wave}
						alt="waveform"
						fill={tile.color}
					></Waveform>
				</div>
				<div className="card-footer p-1">by {tile.username}</div>
				<button onClick={handleDisable} className="button rounded" style={customStyle}>disable</button>
			</div>
		</motion.div>
	);
}
