import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { LandingLogo } from "../Logos/Logos";

function NavBar() {
	return (
		<Navbar fixed="top" variant="dark" bg="dark" expand="sm">
            <Container>
                {/* Logo */}
				<Navbar.Brand href="/">
					<LandingLogo />
                </Navbar.Brand>
                
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {/* Home */}
						<Nav.Link href="/">Home</Nav.Link>
						<Nav.Link href="/studio">Studio</Nav.Link>
						<Nav.Link>Sign Out</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default NavBar;
