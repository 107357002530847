import Grid from "../components/Grid/Grid.jsx";
import user from "../assets/sampleUser.json";
import NavBar from "../components/NavBar/NavBar"
import sampleLoops from "../assets/sampleLoops.json";


export default function Studio() {
  return (
    <div className="min-vh-100 w-100">
      <header className="text-light">
        <NavBar/>
        <p>Hello, {user && user.id && user.username}!</p>
      </header>
      <div className="bg-dark mb-5 p-3">
        <Grid user={user} rawTiles={sampleLoops} />
      </div>
    </div>
  );
}
