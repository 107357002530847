import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "./error-page";

import Home from "./routes/home";
import Studio from "../src/routes/studio"

const router = createBrowserRouter([
  {
    path        : "/",
    element     : <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path        : "/studio",
    element     : <Studio />,
    errorElement: <ErrorPage />,
  }
]);

export { router }

// ~ Nested Children Example:
// {
//   element: <AppLayout />,
//   errorElement: <ErrorPage />,
//   children: [

//   ],
// },