import NavBar from "../components/NavBar/NavBar";

export default function Home() {
	return (
		<div>
			<header>
				<NavBar className="navbar" />
			</header>
			<body className="bg-dark text-light">
			</body>
		</div>
	);
}
