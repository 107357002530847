import "normalize.css";
import "./App.css";

import React, { useState } from "react";
import { RouterProvider } from "react-router-dom";
// import { firebaseApp } from "./firebase";

import { router } from "./router";

export default function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(true);
	const loginHandler = () => setIsLoggedIn(!isLoggedIn);

	return (
		<div className="App bg-dark">
			<div className="App-main">
					<RouterProvider router={router} loginHandler={loginHandler} />
			</div>
		</div>
	);
}
